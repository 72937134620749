import React, { useState } from "react";
import styles from "./Footer.module.scss";
import { useSelector } from "react-redux";
import classnames from "classnames";
import Coffee from "../Coffee/Coffee";
import CreditsDialog from "../CreditsDialog/CreditsDialog";
import { socials } from "../../constants";

const Footer = () => {
  const isDarkMode = useSelector((state) => state.DarkMode.darkMode);
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen((prevstate) => !prevstate);
  };
  return (
    <div
      className={classnames(styles.container, {
        [styles.containerDark]: isDarkMode,
      })}
    >
      <div className={styles.wrapper}>
        <div className={styles.textContainer}>
          <p className={styles.text}> © 2024 Tabassum Mulla</p>
          <p className={styles.credits} onClick={handleClickOpen}>
            Credits
          </p>
        </div>

        <div className={styles.content}>
          <Coffee />
          {socials.map((social, i) => (
            <a
              key={i}
              href={social.link}
              target='_blank'
              rel='noreferrer'>
              <social.icon
                isDarkMode={isDarkMode}
                fontSize='large'
                className={classnames(styles.socialIcon, {
                  [styles.socialIconDarkMode]: isDarkMode,
                })}
              />
            </a>
          ))}

          {open && <CreditsDialog open={open} handleClose={handleClose} />}
        </div>
      </div>
    </div>
  );
};

export default Footer;
