import { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import classnames from "classnames";
import styles from "./SuccessMessage.module.scss";

const SuccessMessage = ({ width = undefined, fontSize = 18 }) => {
    const [showMessage1, setShowMessage1] = useState(false);
    const [showMessage2, setShowMessage2] = useState(false);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setShowMessage1(true);
        }, 500);

        const timeout2 = setTimeout(() => {
            setShowMessage2(true);
        }, 1200);
        return () => [clearTimeout(timeout), clearTimeout(timeout2)];
    }, []);

    return (
        <div
            className={styles.successMessageContainer}
            style={{ width }}>
            <div className={styles.messageContainer}>
                <div
                    className={classnames(styles.messagebubble, {
                        [styles.showMessageBubble1]: showMessage1,
                    })}
                    id='message1'>
                    <Typography
                        variant='body'
                        fontSize={fontSize}>
                        Thank you for reaching out!
                    </Typography>
                </div>
                <Typography
                    variant='body2'
                    className={classnames(styles.dateString, {
                        [styles.showDateString]: showMessage1,
                    })}
                    fontSize={10}>
                    [{new Date().toTimeString()}]
                </Typography>
            </div>
            <div className={styles.messageContainer}>
                <div
                    className={classnames(styles.messagebubble2, {
                        [styles.showMessageBubble2]: showMessage2,
                    })}>
                    <Typography
                        variant='body'
                        fontSize={fontSize}>
                        Your message just showed up in my inbox. Talk to you soon.
                    </Typography>
                    <Typography
                        variant='body'
                        fontSize={fontSize}>
                        Have a great day! {String.fromCodePoint("0x1f603")}
                    </Typography>
                </div>
                <Typography
                    variant='body2'
                    className={classnames(styles.dateString, {
                        [styles.showDateString]: showMessage2,
                    })}
                    fontSize={10}>
                    [{new Date().toTimeString()}]
                </Typography>
            </div>
        </div>
    );
};

export default SuccessMessage;
