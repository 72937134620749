import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Navbar from "./components/Navbar/Navbar";
import Home from "./pages/Home.jsx";
import Contact from "./pages/Contact";
import About from "./pages/About";
import { Routes, Route } from "react-router-dom";
import styles from "./style/App.module.scss";
import Experience from "./pages/Experience";
import Footer from "./components/Footer/Footer";

import classnames from "classnames";
const App = () => {
  const [loading, setLoading] = useState(true);
  const isDarkMode = useSelector((state) => state.DarkMode.darkMode);

  useEffect(() => {
    setTimeout(() => setLoading(false), 5000);
  }, []);

  return !loading ? (
    <div
      className={classnames(styles.container, {
        [styles.darkContainer]: isDarkMode,
      })}>
      <Navbar />
      <Routes>
        <Route
          path='/'
          element={<Home />}
        />
        <Route
          path='/about'
          element={<About />}
        />
        <Route
          path='/experience'
          element={<Experience />}
        />
        <Route
          path='/contact'
          element={<Contact />}
        />
      </Routes>
      <Footer />
    </div>
  ) : (
    <div className={styles.loader}>
      <div className={styles.loadText}>
        <div className={styles.loadedText}>Tabassum</div>
        <div className={styles.loadingText}>Mulla.</div>
      </div>
    </div>
  );
};

export default App;
