import { createSlice } from "@reduxjs/toolkit";

export const darkModeReducer = createSlice({
  name: "darkMode",
  initialState: {
    darkMode: Boolean(JSON.parse(localStorage.getItem("isDarkMode"))),
  },
  reducers: {
    switchOnDarkMode: (state, action) => {
      state.darkMode = true;
    },
    switchOffDarkMode: (state, action) => {
      state.darkMode = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { switchOnDarkMode, switchOffDarkMode } = darkModeReducer.actions;

export default darkModeReducer.reducer;
