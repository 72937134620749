export const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const emailConfig = {
  serviceID: process.env.REACT_APP_EMAIL_SERVICE_ID,
  templateID: process.env.REACT_APP_EMAIL_TEMPLATE_ID,
  url: "https://api.emailjs.com/api/v1.0/email/send",
  publicKey: process.env.REACT_APP_EMAIL_PUBLIC_KEY,
  privateKey: process.env.REACT_APP_EMAIL_PRIVATE_KEY,
};
