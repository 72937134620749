import { Modal, Typography, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import styles from "./CustomModal.module.scss";
import classnames from "classnames";

const CustomModal = ({
    open,
    onClose,
    title,
    modalClassName,
    children,
    isDarkMode,
    footerContent,
    ...props
}) => {
    return <Modal open={open} onClose={onClose}
        className={styles.container}>
        <div className={classnames(styles.wrapper, modalClassName, { [styles.darkMode]: isDarkMode })} {...props}>
            <div className={styles.header}>
                <div className={styles.headerContent}>
                    <Typography className={styles.title}>{title}</Typography>
                </div>
                <IconButton onClick={onClose}>
                    <Close className={styles.closeIcon} />
                </IconButton>
            </div>
            <div className={styles.content}>{children}</div>
            {footerContent ? <div className={styles.footer}>{footerContent}</div> : null}
        </div>
    </Modal>
}

export default CustomModal;