import { useState } from "react";
import styles from "../style/Contact.module.scss";
import { useSelector } from "react-redux";
import classnames from "classnames";
import ContactForm from "../components/ContactForm/ContactForm";
import { Typography } from "@mui/material";
import SuccessMessage from "../components/SuccessMessage/SuccessMessage";

const Contact = () => {
  const isDarkMode = useSelector((state) => state.DarkMode.darkMode);
  const [success, setSuccess] = useState(false);

  return (
    <div
      className={classnames(styles.container, {
        [styles.containerDark]: isDarkMode,
      })}
    >
      <div className={styles.content}>
        <Typography variant={"h4"} className={styles.title}>/ Let's Connect!</Typography>
        <div className={styles.wrapper}>
          <div className={styles.info}>
            {success ?
              <SuccessMessage />
              : <>
                <Typography variant="body">
                  Thank you for visiting my portfolio. I’m always excited to connect with professionals in the industry. Whether you have questions about my work, opportunities to collaborate, or just want to chat about potential career opportunities, I’d love to hear from you!
                </Typography>
                <Typography variant="body" >
                  Feel free to reach out via email at <a href="mailto:tabassum.mulla7@yahoo.co.uk">tabassum.mulla7@yahoo.co.uk</a> or fill out the form below. I’ll get back to you as soon as possible.    Looking forward to connecting!
                </Typography>
              </>
            }
            {!success ?
              <ContactForm setSuccess={setSuccess} success={success} /> : null}
          </div>
        </div>
      </div >
    </div>
  );
};

export default Contact;
