import React, { useState } from "react";
import { Button, Divider, Typography } from "@mui/material";
import ProfilePic from "../assets/images/profile.png";
import Typewriter from "typewriter-effect";
import { useSelector } from "react-redux";
import styles from "../style/HomePage.module.scss";
import classnames from "classnames";
import CustomModal from "../components/CustomModal/CustomModal";
import ContactForm from "../components/ContactForm/ContactForm";
import { socials } from "../constants/index";
const Home = () => {
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const isDarkMode = useSelector((state) => state.DarkMode.darkMode);

  const onRequestCV = () => {
    setOpen(true);
  };


  return (
    <div
      className={classnames(styles.container, {
        [styles.containerDark]: isDarkMode,
      })}>
      <div className={styles.wrapper}>
        <div className={styles.profileImageContainer}>
          <img
            className={styles.profileImage}
            src={ProfilePic}
            alt='profilepic'
          />
        </div>
        <div className={styles.content}>
          <h1 className={styles.title}>
            My name is Tabassum Mulla <span className={styles.wave}>👋🏼</span>{" "}
          </h1>
          <Typewriter
            options={{
              strings: [
                "Software Engineer By Profession",
                "Creative UX Designer",
                "Self Taught Digital Artist",
                "Travel Junkie at Heart",
              ],
              autoStart: true,
              loop: true,
            }}
          />{" "}
        </div>
        <div className={styles.actions}>
          <Button
            onClick={onRequestCV}
            variant='contained'
            size='Large'
            className={classnames(styles.requestCVButton, {
              [styles.requestCVButtonDark]: isDarkMode,
            })}>
            Request CV
          </Button>
        </div>
        <CustomModal
          open={open}
          isDarkMode={isDarkMode}
          onClose={() => setOpen(false)}>
          <div
            className={classnames(styles.modalContent, {
              [styles.successContent]: success,
            })}>
            {!success ? (
              <>
                <div className={styles.modalHeader}>
                  <Typography variant='h3'>
                    Thanks for checking out my portfolio!
                  </Typography>
                  <Typography variant='body'>
                    If you're interested in my full CV, just fill out the form
                    below or shoot me a email {""}
                    <br></br>
                    <a href='mailto:tabassum.mulla7@yahoo.co.uk'>
                      @tabassum.mulla7@yahoo.co.uk
                    </a>{" "}
                    . Can't wait to connect and see how we can work together!{" "}
                  </Typography>
                  <br></br>
                </div>
                <ContactForm setSuccess={setSuccess} />
              </>
            ) : (
              <>
                <div className={styles.followMeCard}>
                  <Typography fontFamily={"Poppins"}>
                    Thank you! 🙌 <br />
                    Your message just landed in my inbox, and I'll be in touch
                    with you soon. Looking forward to chatting!
                  </Typography>
                  <Divider />
                  <Typography
                    variant='subtitle1'
                    fontFamily={"Poppins"}>
                    In the meantime, follow me on social media to stay
                    connected!
                  </Typography>

                  <div className={styles.socials}>
                    <span className={styles.socialRow}>
                      {socials.map((social, i) => (
                        <a
                          key={i}
                          href={social.link}
                          target='_blank'
                          rel='noreferrer'>
                          <social.icon
                            isDarkMode={isDarkMode}
                            fontSize='large'
                            className={classnames(styles.socialIcon, {
                              [styles.socialIconDarkMode]: isDarkMode,
                            })}
                          />
                        </a>
                      ))}
                    </span>
                  </div>
                </div>
              </>
            )}
          </div>
        </CustomModal>
      </div>
    </div>
  );
};
export default Home;
